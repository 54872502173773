<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="12" sm="5">
          <BaseDateInput v-model="startDateTime"
                        :label="$t('accounting.lang_dailyReportStart')"
                        :rules="[v => !!v]" dense outlined
                        type="date"/>
        </v-col>

        <v-col cols="12" sm="5">
          <BaseDateInput v-model="endDateTime"
                        :label="$t('accounting.lang_dailyReportEnd')"
                        :rules="[v => !!v]" dense outlined
                        type="date"/>
        </v-col>

        <v-col cols="12" sm="2" class="mb-3">
          <v-btn :disabled="!isFilter" block class="mx-auto elevation-0" color="primary" @click="filter()">
            <v-icon>filter_list</v-icon>
            {{ $t('generic.lang_filter') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div>
      <Datatable
        ref="orderbonArchiveTable"
        :api-endpoint="ENDPOINTS.DATATABLES.SETTINGS.ORDERBON_ARCHIVE"
        :data="this.params"
        :datatable-headers="datatableHeaders"
        hideOptionsMenu
        :showSelectCheckbox="false"
        show-display-buttons
        @displayEntry="displayEntry"
      />
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        class="internalWidth"
        id="onScreenKeyboard"
        :options="touchKeyboard.options"
        :defaultKeySet="touchKeyboard.keySet"
        v-if="touchKeyboard.visible"
        :layout="touchKeyboard.layout"
        :cancel="hideTouchKeyboard"
        :accept="hideTouchKeyboard"
        :input="touchKeyboard.input"
      />
    </div>
  </div>
</template>

<script>
import Datatable from "../../../datatable/Datatable";
import { ENDPOINTS } from "@/config";
import mixin from "@/mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  name: "OrderbonArchiveComponent",
  components: {
    Datatable,
    BaseDateInput
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      startDateTime: "",
      endDateTime: "",
      isFilter: false,
      datatableHeaders: [
        {
          text: this.$t("generic.lang_id"),
          align: "left",
          value: "uuid",
          hide: true,
        },
        {
          text: this.$t("generic.lang_time") + " / " + this.$t("generic.lang_date"),
          value: "date",
        },
        {
          text: this.$t("settings.lang_userid"),
          value: "user_id",
        },
        {
          text: this.$t("generic.lang_TableNo"),
          value: "tableNo",
        },
        { text: this.$t("generic.lang_party"), value: "tableParty" },
      ],
    };
  },
  computed: {
    params: function () {
      return {
        start_date: this.startDateTime === "" ? "" : new Date(this.startDateTime).getTime() / 1000,
        end_date: this.endDateTime === "" ? "" : new Date(this.endDateTime).getTime() / 1000,
      }
    },
  },
  watch: {
    startDateTime(val) {
      if (new Date(val).getTime() >= new Date(this.endDateTime).getTime()) {
        console.log("startD");
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_select_date_before') + " : " + this.endDateTime
        });
        this.isFilter = false;
      } else {
        this.isFilter = true;
      }
    },
    endDateTime(val) {
      if (new Date(val).getTime() <= new Date(this.startDateTime).getTime()) {
        console.log("endD");
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_select_date_after') + " : " + this.startDateTime
        });
        this.isFilter = false;
      } else {
        this.isFilter = true;
      }
    },
  },
  methods: {
    displayEntry(entry) {
      this.$router.push({
        name: "settings.cashierSettings.displayorderbonarchive",
        params: { id: entry.uuid },
      });
    },
    filter() {
      if (!this.$refs.form.validate()) return;
      this.$refs.orderbonArchiveTable.getDataFromApi();
    },
  },
};
</script>
