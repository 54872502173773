<template>
  <div>
    <page-title
      :heading="$t('settings.lang_orderbonarchive')"
      :subheading="$t('settings.lang_orderbonarchive')"
      :icon="icon"
    ></page-title>

    <div class="app-main__inner">
      <OrderbonArchiveComponent />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import OrderbonArchiveComponent from "../../../components/settings/cashier/orderbonArchive/OrderbonArchiveComponent.vue";

export default {
  name: "OrderbonArchive",

  components: {
    PageTitle,
    OrderbonArchiveComponent,
  },

  data: () => ({
    icon: "pe-7s-search icon-gradient bg-tempting-azure",
  }),
};
</script>
